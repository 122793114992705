import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth.service';
import {
  BaseUser,
  UserType,
  userTypeToJSON,
} from '../../models';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) { }

  updateUser = async (
    grantedUserType: UserType,
    grantedUserId: string,
    toUpdate: BaseUser
  ) => {
    const user = await this.authService.getCurrentUser()

    if (!user || !user.userType) {
      console.error('not authenticated');
      return;
    }

    const insertBody: { [key: string]: unknown } = {
      granterUserId: user.id,
      granterUserType: userTypeToJSON(user.userType),
      grantedUserId: grantedUserId,
      grantedUserType: userTypeToJSON(grantedUserType),
      toUpdate: { user: BaseUser.toJSON(toUpdate) },
    };

    console.log('insertBody', insertBody);

    const token = await this.authService.getIdToken()

    const updateRes = await firstValueFrom(this.http.post(environment.enrollhereBaseUrl + '/api/v1/cp/dialer/user/update', insertBody, {
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    }))

    console.log('update permissions res', updateRes);

    return updateRes;
  }
}
